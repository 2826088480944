<template>
  <div class="coupon-exchange-setting">
    <PageTitle
      :title="displayData.pageTitle"
      :btn="displayData.buttons.exportUsage"
      :btn2="displayData.buttons.exportAdded"
      @btnClick="modal.exportUsage = true"
      @btn2Click="modal.exportAdded = true"
    />

    <div class="filters-wrapper">
      <div class="filter-row">
        <p class="label">{{ displayData.labels.filter }}</p>
        <BaseElSelect
          v-model="search.status"
          class="test"
          :placeholder="displayData.placeholders.status"
          clearable
          @change="refresh(true)"
        >
          <BaseElSelectOption
            v-for="option in couponStatusConfig"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </BaseElSelect>
        <BaseElSelect v-model="search.filterTimeType" class="test" clearable @change="refresh(true)">
          <BaseElSelectOption
            v-for="option in filterTimeCategoryConfig"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </BaseElSelect>
        <el-date-picker
          v-model="search.range"
          editable
          :disabled="!search.filterTimeType"
          type="datetimerange"
          range-separator="至"
          :start-placeholder="displayData.placeholders.startDate"
          :end-placeholder="displayData.placeholders.endDate"
          format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
          @change="refresh(true)"
        />
      </div>
      <div class="filter-row">
        <p class="label">{{ displayData.labels.search }}</p>
        <SearchTextInput class="test2" :value.sync="search.code" :placeholder="displayData.placeholders.couponCode" @refresh="refresh(true)" />
        <MemberSearch class="test2" :model.sync="search.member" @change="refresh(true)" />
        <SearchTextInput class="test2" :value.sync="search.exchangeCode" :placeholder="displayData.placeholders.exchangeCode" @refresh="refresh(true)" />
      </div>
      <div class="filter-row">
        <p class="label">{{ displayData.labels.sort }}</p>
        <BaseElSelect v-model="search.orderBy" clearable @change="refresh(true)">
          <BaseElSelectOption
            v-for="option in orderByOptionsConfig"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </BaseElSelect>
      </div>
    </div>

    <CouponUsageRecordTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />

    <CouponExportOptionModal
      v-if="modal.exportUsage"
      :title="displayData.modals.exportUsage"
      @close="modal.exportUsage = false"
      @confirm="onExport($event, 'used')"
    />
    <CouponExportOptionModal
      v-if="modal.exportAdded"
      :title="displayData.modals.exportAdded"
      @close="modal.exportAdded = false"
      @confirm="onExport($event, 'added')"
    />
    <ExportDialog
      v-if="exportState.modal"
      :title="exportTypeLabel"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="resetExport"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, unref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import CouponUsageRecordTable from './components/CouponUsageRecordTable.vue'
import { orderByOptionsConfig, couponStatusConfig, filterTimeCategoryConfig, couponOriginTypeConfig, couponTypeConfig } from '@/config/coupon'
import SearchTextInput from '@/components/Input/SearchTextInput.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import CouponExportOptionModal from './components/CouponExportOptionModal.vue'
import { GetCouponRecord, GetCouponRecordCount } from '@/api/lottery/couponRecord'
import { get, omitBy, flatten } from 'lodash'
import { useFetch } from '@/use/fetch'
import { useExport } from '@/use/export'
import { ExportExcel } from '@/utils/excel'
import { formatDate } from '@/utils/date'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import dayjs from '@/lib/dayjs'
import { useExportCenter } from '@/use/useExportCenter'
import { CreateCouponRecordCreateSheetExportTask, CreateCouponRecordUseSheetExportTask } from '@/api/exportTask'

export default defineComponent({
  name: 'CouponUsageRecord',
  components: {
    PageTitle,
    CouponUsageRecordTable,
    SearchTextInput,
    MemberSearch,
    CouponExportOptionModal,
    ExportDialog,
  },
  setup (props) {
    const exportTypeLabel = ref('')
    const modal = reactive({
      exportOptions: false,
      exportUsage: false,
      exportAdded: false,
    })
    const router = useRouter()
    const route = useRoute()
    const { simpleFetch } = useFetch()
    const { messageOptions } = useExportCenter()
    const { exportState, resetExport, getExportData, getExportDataOld, totalDataCount, totalPercentege, curTotalDataCount } = useExport()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
      search,
      extendData,
      updateRouteQuery,
    } = useTable()

    extendData('search', {
      orderBy: null,
      status: null,
      filterTimeType: null,
      range: null,
      code: null,
      exchangeCode: null,
      member: null,
    })
    extendData('loading', {
      export: false,
    })

    // !! 頁面顯示資料
    const displayData = computed(() => {
      return {
        pageTitle: '票券使用紀錄',
        buttons: {
          exportUsage: '匯出使用紀錄',
          exportAdded: '匯出新增紀錄',
        },
        labels: {
          filter: '篩選',
          search: '搜尋',
          sort: '排序',
        },
        placeholders: {
          status: '票券狀態',
          startDate: '開始日期',
          endDate: '结束日期',
          couponCode: '請輸入票券編號',
          exchangeCode: '請輸入兌換碼',
        },
        modals: {
          exportUsage: '匯出使用紀錄',
          exportAdded: '匯出新增紀錄',
        },
      }
    })

    // ?? 路由查詢資料
    const routeQuery = computed(() => {
      const query = {
        ...search,
        page: tableOptions.page,
      }

      query.member = get(search, 'member.id')
      if (query.range) {
        delete query.range
        query.range1 = dayjs(search.range[0]).valueOf()
        query.range2 = dayjs(search.range[1]).valueOf()
      }

      return omitBy(query, (item) => !item)
    })

    // ?? 取得資料 payload
    const getDataPayload = computed(() => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        MemberId: get(search, 'member.id') || undefined,
        sort: search.orderBy || undefined,
        status: search.status || undefined,
        dateRangeType: search.filterTimeType || undefined,
        startAt: search.filterTimeType ? get(search.range, '[0]') : undefined,
        endAt: search.filterTimeType ? get(search.range, '[1]') : undefined,
        code: search.code || undefined,
        exchangeCode: search.exchangeCode || undefined,
      }

      return payload
    })

    // => 取得表格資料
    const getTableData = async () => {
      await Promise.allSettled([
        fetchData(GetCouponRecord, getDataPayload.value),
        fetchDataCount(GetCouponRecordCount, getDataPayload.value),
      ])
    }

    // => 刷新
    const refresh = async (searchStatus = false) => {
      if (searchStatus) {
        tableOptions.page = 1
        if (!search.filterTimeType) {
          search.range = null
        }
      }
      loading.table = true
      await getTableData()
      updateRouteQuery(routeQuery.value)
      loading.table = false
    }

    // => 格式化匯出資料 (棄用)
    // const formateExportData = (data) => {
    //   data = flatten(data)
    //   const sheetsData = []
    //   data.forEach((item) => {
    //     const couponType = get(item, 'type')
    //     const originType = get(item, 'originType')
    //     const usedAtDate = get(item, 'usedAt')
    //     const expAtDate = get(item, 'expAt')
    //     const giveAtDate = get(item, 'createdAt')

    //     const row = {
    //       會員編號: get(item, 'MemberId') || '-',
    //       會員名稱: get(item, 'Member.UserInfo.name') || '-',
    //       會員手機: get(item, 'Member.UserInfo.phone') || '-',
    //       票券來源: get(couponOriginTypeConfig, `${originType}.label`) || '-',
    //       票券編號: get(item, 'code'),
    //       票券種類: get(couponTypeConfig, `${item.type}.label`),
    //       票券名稱: get(item, 'Coupon.name'),
    //       兌換碼: get(item, 'exchangeCode') || '-',
    //       // 票券使用狀態: usedAtDate ? '已使用' : '未使用',
    //       票券使用狀態: get(couponStatusConfig, `${item.status}.label`),
    //       票券發送日期: giveAtDate ? formatDate(giveAtDate) : '-',
    //       票券使用日期: usedAtDate ? formatDate(usedAtDate) : '-',
    //       票券到期日期: expAtDate ? formatDate(expAtDate) : '-',
    //     }
    //     if (couponType !== 'exchange') delete row['兌換碼']

    //     sheetsData.push(row)
    //   })
    //   return sheetsData
    // }

    // => 本地匯出 (棄用)
    // const onExport = async (couponType, exportType) => {
    //   const payload = unref(getDataPayload)
    //   const couponTypeLabel = get(couponTypeConfig, `${couponType}.label`)
    //   payload.type = couponType
    //   payload.limit = 100
    //   let sheetName
    //   if (exportType === 'used') {
    //     exportTypeLabel.value = `匯出${couponTypeLabel}使用紀錄`
    //     exportState.title = `匯出${couponTypeLabel}使用紀錄`
    //     sheetName = `${couponTypeLabel}使用紀錄`
    //     payload.isUsed = true
    //   } else if (exportType === 'added') {
    //     exportTypeLabel.value = `匯出${couponTypeLabel}新增紀錄`
    //     exportState.title = `匯出${couponTypeLabel}新增紀錄`
    //     sheetName = `${couponTypeLabel}新增紀錄`
    //     payload.isUsed = false
    //   }

    //   try {
    //     modal.exportAdded = false
    //     modal.exportUsage = false
    //     exportState.title = '匯出中請稍後...'
    //     exportState.modal = true
    //     await simpleFetch(GetCouponRecordCount, getDataPayload.value,
    //       (res) => {
    //         exportState.dataCount = [res.count]
    //       }, true)

    //     if (!totalDataCount.value) {
    //       exportState.content = '尚無資料可匯出'
    //       exportState.error = true
    //       if (exportType === 'used') window.$message.warning('篩選條件內無可匯出的使用紀錄')
    //       else if (exportType === 'added') window.$message.warning('篩選條件內無可匯出的新增紀錄')
    //       return
    //     }
    //     await getExportData({ stage: 0, fetchAPI: GetCouponRecord, payload: getDataPayload.value, throwErr: true })

    //     const formatData = formateExportData(exportState.exportData)
    //     exportState.exportting = false
    //     exportState.title = '匯出成功'

    //     ExportExcel(formatData, sheetName, sheetName)
    //   } catch (error) {
    //     exportState.error = true
    //     exportState.exportting = false
    //     window.$message.error(error)
    //   }

    //   // 取得所有資料
    //   // 格式化匯出資料
    //   // 匯出
    // }

    // => 匯出
    const onExport = async (couponType, recordType) => {
      const createSheetExportTask = async (apiFunc, payload) => {
        const [, err] = await apiFunc(payload)
        modal.exportAdded = false
        modal.exportUsage = false
        loading.export = false
        if (err) {
          window.$message.error(err)
          return
        }
        window.$message(messageOptions.value)
      }

      const payload = {
        shopId: shopId.value,
        MemberId: get(search.member, 'id') || undefined,
        status: search.status || undefined,
        dateRangeType: search.filterTimeType || undefined,
        startAt: search.filterTimeType ? get(search.range, '[0]') : undefined,
        endAt: search.filterTimeType ? get(search.range, '[1]') : undefined,
        code: search.code || undefined,
        exchangeCode: search.exchangeCode || undefined,
        type: couponType,
      }

      loading.export = true

      if (recordType === 'used') {
        await createSheetExportTask(CreateCouponRecordUseSheetExportTask, payload)
      } else if (recordType === 'added') {
        await createSheetExportTask(CreateCouponRecordCreateSheetExportTask, payload)
      }
    }

    // => 同步路由查詢資料
    const syncRouteQuery = () => {
      const query = route.currentRoute.query
      if (!query) return
      search.member = query.memebr
    }

    onMounted(() => {
      syncRouteQuery()
      refresh()
    })

    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      orderByOptionsConfig,
      refresh,
      search,
      couponStatusConfig,
      filterTimeCategoryConfig,
      modal,
      onExport,
      exportState,
      resetExport,
      getExportDataOld,
      totalDataCount,
      totalPercentege,
      curTotalDataCount,
      exportTypeLabel,
      displayData,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .test.el-select {
  max-width: 154px !important;
}
::v-deep .test .el-input {
  max-width: 154px !important;
}
::v-deep .test2.el-select {
  max-width: 208px !important;
}
::v-deep .test2.el-input {
  width: 100% !important;
  max-width: 208px !important;
}
::v-deep .test2 .el-input {
  width: 100% !important;
  max-width: 208px !important;
}
::v-deep .test2 .el-input__inner {
  width: 100% !important
}
.filters-wrapper {
  @apply flex flex-col gap-[20px] mb-[20px];
}
.filter-row {
  @apply flex items-center gap-[8px];
}

.filter-row .label {
  @apply flex-shrink-0;
}
</style>
